@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 20px;
  width: 700px;

  .text {
    color: $color-sub-info;
  }

  .buttons {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.padding {
  padding: 1rem 0;
}

.error {
  color: red;
}

.fileSelect {
  width: 200px;
  height: 200px;
  background-color: green;

  .fileName {
    font-size: 14px;
    line-height: 16px;
    color: $color-background-main;
    padding-left: 0.625rem;
  }
}

.choseFileHint {
  font-size: 14px;
  line-height: 16px;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  color: $color-gray;
}

.gridPermissions {
  margin-bottom: 1rem;
}

.containerTitle {
  font-size: 20px;
  font-weight: bold;
}

.containerSubtitle {
  margin-top: 1rem;
  font-size: 14px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  border-bottom: solid black 1px;
}

.rowText {
  text-align: center;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  flex: 1;
}


