.container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow: hidden;

  .content {
    padding-top: 1.125rem;
    overflow: scroll;
  }
}
