
.message {
  padding-left: 20px;
}
.ipadMessage {
  display: none;
}
@media only screen and (max-width:1500px) {

    .ipadMessage {
      margin-top: 5px;
      display: inline;
      padding-left: 20px;
    }
}

.confirmDocument {
  height: 100%;
  width: 100%;

  padding: 20px;
}