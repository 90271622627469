.workflowPageContainer {
    max-width: 80%;
    margin: auto;
    padding-top: 30px;
}

.necessityLetterContainer {
    min-width: 60%;
    max-width: 80%;
    margin: auto;
    padding-top: 30px;
}