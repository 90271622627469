.emptyText {
  margin-top: 0.625rem;
}

.receipt {
  margin-top: 10px;
}

.headButton {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
