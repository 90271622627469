@import 'scss/base/colors.scss';

.container{
   position: relative;
    height: 100%;
}

.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-main-blue;
  width: 175px;
  float: right;
  text-align: center;
  color: white;
  border-right: white 1px solid;
  height: 100%;

  .icon {
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit; 
  }
}

.excludeContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0, auto;
  pointer-events: none;

  .exclusionIcon {
    float: right;
    margin: 1px;
    padding: 4px;
    pointer-events: auto;
  }
}

.disabled {
    background-color: #8E98C8;
    color: $color-text-secondary;
}
.exclude {
  background-color: $color-day-exception-title;
}