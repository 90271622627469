@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;

  .containerHeader {
    display: flex;
    justify-content: space-between;
    .appointmentOpeningInfo{
      p {
        margin: 0;
        margin-bottom: $margin-medium;
        font-weight: bolder;
        font-size: $font-size-small;
      }
    }
  }

  .buttonSection {
    display: flex;
    justify-content: flex-end;
  }
}