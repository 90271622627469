@import './base/colors.scss';
@import './base/sizes.scss';
@import './base/grid.scss';
@import './base/media-queries.scss';

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

.MuiListItem-root.Mui-selected {
  &::before {
    content: ' ';
    display: inline-block;
    transform: rotate(45deg);
    height: 14px;
    width: 8px;
    margin-right: 10px;
    border-bottom: solid 4px #3f51b5;
    border-right: solid 4px #3f51b5;
  }
}

.MuiSelect-selectMenu.MuiSelect-select:focus {
  background-color: transparent;
}
