@import 'scss/base/colors.scss';

.title {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: $color-background-main;
  cursor: pointer;
}

.content {
  padding-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $color-background-main;
  opacity: 0.5;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.padding {
  padding: 0.625rem;
}
