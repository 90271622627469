@import 'scss/base/sizes.scss';

.main {
  padding-top: $padding-2larger;
  width: 100%;
  height: fit-content;
  border-top: 1px solid #e5e5e5;

  &:first-child {
    border: none;
  }

  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-medium;

    &.statement {
      white-space: pre-wrap;
      font-size: $font-size-small;
    }
  }

  .answerContainer {
    display: flex;
    justify-content: center;

    .answer {
      display: flex;
      flex-direction: column;
      padding: $radius-large 0;

      &.multipleChoice {
        > * {
          margin-bottom: $margin-medium;
        }
      }

      &.open {
        width: 100%;
        padding-left: 4rem;
        padding-right: 4rem;
      }

      &.booleanAnswer {
        flex-direction: row;

        .button {
          width: 4rem;

          &:not(:last-child) {
            margin-right: $margin-5px;
          }

          &.notActive {
            background: #8c8c8c;
          }
        }
      }

      .img {
        max-width: 400px;
        max-height: 400px;
      }
    }
  }

  .reqMarker {
    color: red;
  }
}

.dropDownContainer{
  width:200px;
}

.openChoiceOption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;

    .title {
      margin-right: $margin-medium;
    }
}
