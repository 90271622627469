
.container{
    border: 1px solid white;
    background: #4A5CC0;

    .inlineWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

   .expanded {
        padding: 0;
        margin: 0px 0;
    }

    .summary > div {
        margin: 0px 0;
        padding: 0px 0px 0px 16px;
        height: 40px;
        min-height: min-content;
    }

    .summary > div > div {
        margin: 0px 0;
        height: 40px;
        min-height: min-content;
    }
    .panelSummary {
        background: #4A5CC0;
        min-height: 1%;
        margin: 0px 0;

    }
    .panelRow {
        color: #FFFFFF;
        margin: 0px 0;
        text-align: center;
        min-width: 306px;
    }
    .panelRowArrowClose {
            color: white;
            transform: rotate(-90deg);
            transition: 0.2s;
    }
    .panelRowArrowOpen {
            color: white;
            transform: rotate(0deg);
            transition: 0.2s;

    }
    .panelRowEditIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        height: 100%;
        path {
            fill: white;
        }
    }

    .scheduleBtnContainer {  
    display: flex;
    flex-direction: row;
        margin-right: 15px;
    }

}