/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-primary: #b00000;
$color-secondary: #b1df95;
$color-alert: #ff0000;
$color-success: #b1df95;
$color-gray: #4a4a4a;
$color-blue: #4a5cc0;
$color-day-exception:#ff000080;
$color-day-exception-title:#7e0000;

//  colour background
$color-background: #ffffff;
$color-list-background: #f1f3ff;
//$color-background-secondary: $color-blue;
$color-background-secondary: #551d00;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;
$color-background-main: #26315a;
$color-main-blue: #4a5cc0;
$color-background-filtered: #eaeaea;

// colour text
$color-text: #787878;
$color-sub-info: #999999;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;

$color-border: #a3a3a3;

// buttons
$color-button-background: $color-background;
$color-button-text: $color-secondary;

$color-button-background-disabled: #c7c7c7;

// borders:
$color-border: $color-primary;
$color-border-secondary: $color-blue;
$color-light-grey-border: #ebebeb;
$color-dark-grey-border: #a3a3a3;
$color-light-blue-border: #c3ccff;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;

// loading:
$color-loading: $color-primary;

// Link:
$color-link: #3f51b5;

// hover:
$color-hover: #c20200;

// appointment statuses:
$color-not-confirmed: #e47573;
$color-need-moved: #bc67dc;
$color-cancelled: #fd0400;
$color-deleted: #00fda4;
$color-confirmed: #1aaf5d;
$color-arrived: #287df0;
$color-departed: #239bde;
$color-billed: #4fccf4;
$color-follow-up-scheduled: #f3ca3e;
$color-no-show: #f49a40;
$color-not-performed: #6a00ff;

// forms:
$color-sent-to-patient: #fd0400;
$color-complete: #1aaf5d;

// documents
$color-flagged: #FF000070;
