.main {
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  align-items: center;
  width: fit-content;

  .month {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #26315a;
  }
}
