@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;
}


.formContainer {
  display: flex;

  .formColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;

    &:last-child {
      border: none;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .formFieldContainer {
    margin-bottom: $margin-medium;
  }
}

.checkbox {
  display: inline-block;
}

.formPII {
  display: inline-block;
}

.formPIILabel {
  display: inline-block;
}

.formStatusLabel {
  display: inline-block;
  font-weight: bold;
  font-size: $font-size20px;
}

.formStatus {
  display: inline-block;
  font-weight: normal;
}