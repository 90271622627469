@import 'scss/base/sizes.scss';
@import 'scss/base/colors.scss';

@media only screen and (min-width:980px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    
    
    div {
      &.toolbar {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.toolbarItem {
        display: flex;
        flex-direction: row;
        line-height: 25px;
        align-items: flex-start;
        padding: 10px;
        padding-bottom: 3px;
        align-items: center;
        margin: 10px;
        margin-left: 0px;

        .title {
          padding: 10px;
          white-space: nowrap;
        }
      }
      &.leftToolbar {
        display: flex;
        flex-direction: row;
      }
      &.rightToolbar {
        display: flex;
        flex-direction: row;
      }
      &.toggleButtons {
        display: flex;
        flex-direction: row;
        line-height: 25px;
        align-items: center;
        padding: 3px;
        padding-bottom: 3px;
        align-items: right;
        margin: 3px;
        margin-left: 0px;
      }
      &.main {
        width:100%;
        flex: 1;
        height:100%;
      }
      &.buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $padding-larger $padding-2larger;
        box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
        z-index: 2;
        font-size: 1.15em;
    
        .button {
          &:first-child {
            margin-right: auto;
          }
          &:last-child {
            margin-left: $margin-larger;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:980px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    
    div {
      &.toolbar {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.toolbarItem {
        display: flex;
        flex-direction: row;
        line-height: 25px;
        align-items: flex-start;
        padding: 5px;
        padding-bottom: 3px;
        align-items: center;
        margin: 5px;
        margin-left: 0px;

        

        .title {
          padding: 10px;
          white-space: nowrap;
        }
      }
      &.colourPicker {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        align-items: center;
        margin: 0px;

        .title {
          padding: 10px;
          white-space: nowrap;
        }
      }
      &.toggleButtons {
        display: flex;
        flex-direction: row;
        line-height: 25px;
        justify-content: center;
        align-items: flex-end;
        padding: 3px;
        padding-bottom: 3px;
        align-items: right;
        margin: 3px;
        margin-left: 0px;
      }
      &.leftToolbar {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &.rightToolbar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      &.main {
        width:100%;
        flex: 1;
        height:100%;
      }
      &.buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: $padding-larger $padding-2larger;
        box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
        z-index: 2;
        font-size: 1.15em;
    
        .button {
          &:first-child {
            margin-right: auto;
          }
          &:last-child {
            margin-left: $margin-larger;
          }
        }
      }
    }
  }
}

.reload {
  display: none;
}

.loading {
  padding-top: 300px;
  margin: auto;
  .title {
    font-size: 30px;
  }
  .message{
    font-size: 15px;
  }
  .ipadMessage {
    display: none;
  }
}

.pdfViewer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  padding: 0 2.5rem;
  padding-top: 1rem;
  align-items: center;
  background-color: #eee;

  &.hide {
    display: none;
  }

  &.freezeScreen {
    overflow: hidden;
    touch-action: none;
  }

  &.canvasWrapper {
    box-shadow: 0 0 3px #bbb;
  }
  >div {
    background-color: 'red';
    margin-bottom: 10px;
    position: relative;
    >svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    >div:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &.page {
    border-image: none;
  }
}

@media only screen and (max-width:1500px) {
 

  .reload {
    display: flex;
    justify-content: center;
    height: 25px;
    font-weight: bold;
    margin: 10px 0px;
  
    .message {
      line-height: 30px;
  
    }
  }

  .loading {
    .ipadMessage {
      margin-top: 5px;
      display: inline;
      font-size: 15px;
    }
  }
}

