$padding-small: 0.5rem;
$padding-medium: 0.75rem;
$padding-large: 1rem;
$padding-larger: 1.25rem;
$padding-2larger: 2.5rem;

$radius-small: 0.5rem;
$radius: 1rem;
$radius-large: 3rem;

$margin-3px: 0.1875rem;
$margin-5px: 0.3125rem;
$margin-small: 0.5rem;
$margin-medium: 0.75rem;
$margin-large: 1rem;
$margin-larger: 1.25rem;

$font-size-tiny: 0.6rem;
$font-size-small: 0.875rem;
$font-size: 1rem;
$font-size18px: 1.125rem;
$font-size20px: 1.25rem;
$font-size23px: 1.4375rem;
$font-size-medium: 1.5rem;
$font-size-large: 2rem;
$font-size-link: 1.3125rem;
$border-size: 1px;

$spacing-small: 0.5rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

$page-left-right-padding: 40px;
$page-top-bottom-padding: 21px;
$page-fixed-header-content-top-padding: 100px;
$page-fixed-header-content-top-padding-extra-location-filter: 110px;

$appointment-calendar-block-height: 45px;
$appointment-calendar-five-min-block-height: 15px;
$appointment-calendar-block-width: 70px;
