.dialogBody {
  .buttonSection {
    display: flex;
    flex-direction: row;
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  .validationError {
    color: red;
    font-size: 13px;
    font-weight: 100;
  }

  .textError {
    color: red;
    text-align: end;
  }
}

.rightContent {
  justify-content: flex-end;
}

.withPadding {
  padding: 0 10px 10px 10px;
}
