@import 'scss/base/sizes.scss';
@import 'scss/base/colors.scss';

.main {
  display: flex;
  flex-direction: row;
  align-items: center;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: $margin-larger;

    .title {
      font-size: $font-size20px;
      line-height: $font-size23px;
      color: $color-background-main;
      cursor: pointer;
    }

    .subInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-small;
      line-height: $font-size;
      color: $color-background-main;
      opacity: 0.5;

      .left {
        display: flex;
      }

      .right {
        display: flex;
        align-self: flex-end;
        text-transform: capitalize;
      }
    }
  }
}
