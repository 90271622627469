.main {
  display: flex;
  flex-direction: row;
  flex: 1;

  .field {
    &:not(:last-child) {
      padding-right: 5px;
    }
  }

  .inputLabel {
    white-space: nowrap;
  }
}
