.container {
  display: flex;
  flex-direction: row;

  .value {
    padding: 0 5px;
  }

  .name {
    font-weight: bold;
  }
}
