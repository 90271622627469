.margin {
  padding: 20px;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.25rem;
}

.subtitle {
  font-size: 1rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.6875rem;

  .marginLeft {
    margin-left: 1.25rem;
  }
}
