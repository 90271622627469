@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  .appointmentOpeningInfo{
    p {
      margin: 0;
      margin-bottom: $margin-medium;
      font-weight: bolder;
      font-size: $font-size-small;
    }
  
    .blue {
      color: $color-blue;
    }
  }
}

.formContainer {
  display: flex;

  .pdfColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;
  }

  .showingPdf {
    max-width: 225px;
  }

  .hidingPdf {
    min-width: 300px;
  }
  
  .formColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;

    &:last-child {
      border: none;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    .availableOpening {
      font-weight: bold;
      font-size: $font-size-small;
      margin: 0;
      margin-bottom: $margin-5px;

      span {
        font-weight: normal;
      }

      &.openingTime {
        margin-bottom: $margin-larger;
      }
    }

    .steps {
      font-weight: bolder;
      font-size: $font-size-small;

      span {
        color: $color-blue;
        font-weight: bolder;
        font-size: $font-size-small;
      }

      &.step3 {
        margin-top: $margin-larger * 2;
      }

      &.step5 {
        margin-top: $margin-larger * 2;
      }
    }

    .possiblePatients {
      overflow-y: scroll;
    }
  }

  .formFieldContainer {
    margin-bottom: $margin-medium;
  }
}

.checkbox {
  display: flex;
  align-items: center;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
}

.newPatient {
  justify-content: center;
}

.buttonPlus {
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.buttonPlus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #4052b6;
  height: 50%;
  width: 3px;
  border-radius: 4px;
}

.buttonPlus:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  height: 3px;
  width: 50%;
  background: #4052b6;
  top: 50%;
  left: 50%;
  border-radius: 4px;
}

.textDisabled {
  color: $color-text-disabled;
}

.textError {
  color: $color-alert;
  text-align: center;
}