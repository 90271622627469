@import 'scss/base.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  background-color: white;
  z-index: 9;
  
  padding-top: $page-top-bottom-padding;

  .title {
    display: flex;
    font-weight: lighter;
    font-size: 2em;

  }

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.rightContent {
  justify-content: flex-end;
}

.leftContent {
  margin-right: 300px;
}

.topBarItem {
  margin: 2px;
}

.topBarItemMargin {
  margin: 2px;
}

.location {
  margin-top: 12px;
  margin-left: 18px;
}

.selectors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-left: 90px;

      .button {
        cursor: pointer;
        font-weight: bold;
        font-size: 21px;

        &.active {
          color: #b00000;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .marginLeft {
      margin-left: 40px;
    }

    .marginTop {
      margin-top: 2px;
    }
  }
}
