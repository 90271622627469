@import 'scss/base/colors.scss';

.padding {
  padding: 0.625rem;
}

.fileSelect {
  width: 200px;
  height: 200px;
  background-color: green;

  .fileName {
    font-size: 14px;
    line-height: 16px;
    color: $color-background-main;
    padding-left: 0.625rem;
  }
}

.choseFileHint {
  font-size: 14px;
  line-height: 16px;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  color: $color-gray;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .marginLeft {
    margin-left: 1.25rem;
  }
}
