.coverLetter {
  padding: 2.5rem 2.5rem;
}

.header {
  
}

.date {

}

.title {
  font-size: 1.2rem;
}

.pageTitle {
  font-size: 2rem;
}

.dob {

}
