@import 'scss/base/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid $color-background-main;

  .link {
    font-size: 1.1rem;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-background-main;
    padding-bottom: 0.5rem;
    margin: 0px 5px;

    &.selected {
      color: $color-primary;
      font-weight: bold;
    }
  }
}

/* Media Queries */
// Tablet
@media only screen and (max-width:980px) {
  .container {
    .link {
      min-height: 75px;
    }
  }
}
