.left {
  padding-right: 1.25rem;
}

.right {
  padding-left: 1.25rem;
}

.button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}