.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .doctorInfo {
  }

  .calendar {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
}
