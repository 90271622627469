@import 'scss/base/colors.scss';

.container {
  padding: 20px;

  .text {
    color: $color-sub-info;
  }

  .buttons {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
