@import 'scss/base/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}

.progress {
  position: absolute;
  top: 25%;
  left: 45%;
  color: $color-primary !important;
}
