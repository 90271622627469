@import 'scss/base/colors.scss';

.container {
  padding: 1px;
  border-radius: 4px;
  background: #f1fff7;
  font-size: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .editIcon {
    margin-right: 4px;
    path {
      cursor: pointer;
    }
  }

  .staffContent {
    flex: 1;
    overflow: auto;
  }
}

.isHighlighted {
  border: 1px solid black;
}

.isNotHighlighted {
  opacity: 0.5;
}

.Confirmed {
  margin: 5px;
  padding: 1px;
  border-radius: 5px;
  background: #f1fff7;
}

.Unconfirmed {
  margin: 5px;
  padding: 1px;
  border-radius: 5px;
  background-color: #fecfcf;
}

.NoStaff {
  background-color: #fecfcf;
}

.Nurse {
  background-color: #fadefd;
}

.Doctor {
  background: #f1fff7;
}

.Tech {
  background: #dbd5ff;
}

.Admin {
  background: #fff0d5;
}

.GeneralStaff {
  background-color: #fff1a5;
}

.Cancelled {
  background-color: $color-day-exception;
}
