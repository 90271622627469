.dialogBody {
  .fields {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.6875rem;

    .marginLeft {
      margin-left: 1.25rem;
    }
  }
}

.rightContent {
  justify-content: flex-end;
}

.withPadding {
  padding: 0 10px 10px 10px;
}

.selectItem {
	text-transform: capitalize;
}
