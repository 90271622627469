@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.dailyColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 10px;
  position: relative;
  .dailyAppointmentsContainer {
    flex: 1;
    border-top: solid 1px $color-light-grey-border;
  }

  .dailyHoursContainer {
    .locationHoursLabel {
      padding: 10px;
      border-bottom: solid 1px $color-light-grey-border;

      p {
        margin: 0;
      }

      span {
        font-weight: bolder;
      }
    }
  }

  .quarterHour {
    border-top: 1px solid $color-light-blue-border;
  }

  .dailyHours {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hourBlock {
    margin: 0;
    padding: 0 5px;
    text-align: center;
    min-height: $appointment-calendar-five-min-block-height;
    max-height: $appointment-calendar-block-height;
    line-height: $appointment-calendar-block-height;
    font-size: 14px;
    border-bottom: solid 1px $color-light-grey-border;
    border-right: solid 1px $color-light-grey-border;

    .hourLabel {
      display: none;
    }
  }

  .hourBlockSelected {
    background-color: $color-blue;
  }

  &.hoursColumn {
    max-width: $appointment-calendar-block-width;
    width: $appointment-calendar-block-width;

    .hourBlock {
      background-color: #93a3fb62;
    }

    .hourLabel {
      display: block;
    }
  }
}
.hourBlock:active {
  background-color: $color-blue;
}

.appointmentContainer {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
}

.unavailableTimeCell {
  background-color: rgb(99, 99, 99);
  pointer-events: none;
  opacity: 0.14;
}

.disableClickOnly {
  pointer-events: none;
}

.exception {
  background-color: $color-alert;
}