.container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background: #F1F3FF;
    width: 210px;
    padding-left: 15px;
    height: 100%;
    border-right: solid;
    border-right-width: 2px;
    border-right-color: #D7D6DB;
    font-size: 12px;
   
    .title {
        font-size: 15px;
    }

    .day{
        padding-left: 5px;
    }
}