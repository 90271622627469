@import 'scss/base/colors.scss';

.container {
    display: flex;
    font-size: 10px;
    font-style: normal;
    width: 100%;
    height: 100%;
  
  .scheduleTypeContainer {
    height: 100%;
    background: #F1F3FF;
    display: flex;
    justify-content: space-around;
    min-height: 50px;
    flex-direction: column;
  }
  .scheduleType {
    text-align: center;
    background: #F1F3FF;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }

  .border {
    border-top-color: #f1f3ffc5;
    border-bottom-color: #f1f3ffc5;
  }

  .scheduleContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: '5px 5px';
    grid-auto-flow: dense;
    background-color: white;
    grid-auto-rows: minmax(min-content, auto);
    border-right: white 1px solid;

    >:nth-child(2n-1) {
      background-color: #F1F3FF;
    }

    .bottomBorderCell {
      border-bottom: solid;
      border-bottom-width: 2px;
      border-bottom-color: #D7D6DB;
    }

    .disabled {
      background-color: #e5e5e5;
    }
    .dateException {
      background-color: $color-day-exception;
    }
  }
 
  .scheduled {
    position: relative;
    width: 175px;
  }
}