@import 'scss/base/colors.scss';

.dialogBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 750px;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flexEnd {
    justify-content: flex-end;
  }
}

.invoiceContainer {
  margin: 15px 0px;
}

.padding {
  padding: 0.625rem;
}

.fullFlex {
  flex: 1;
}

.disabled {
  color: $color-text-disabled;
}
.bottom {
  justify-content: flex-end;
}
