@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 40px;
  border-bottom: 1px solid black;
  border-radius: $margin-3px;
  margin: $margin-5px;
  padding: $padding-small;
  background-color: $color-list-background;
  
  .icon {
    float: right;
  }
  
  .info {
    float: left;
    font-size: $font-size;
    width: 90%;
    text-overflow: ellipsis;
  }
}

