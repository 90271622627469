
.container{
  padding: 0.625rem;
  min-width: 500px;
}

.fields {
  margin-bottom: 10px;
  padding: .625rem;
  min-width: 350px;

}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .625rem;

  .left,
  .right {
    display: flex;
  }

  .marginLeft {
    margin-left: 1.25rem;
  }
}