@import 'scss/base/colors.scss';

.titleContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;

  .tasksAmount {
    font-size: 36px;
    line-height: 42px;
  }

  .addBtnIcon {
    width: 26px;
    height: 26px;
    display: flex;
    background: $color-primary;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: 10px;
  }
}

.taskList {
  padding-top: 20px;
  padding-left: 20px;

  .task {
    width: 270px;
  }
}
