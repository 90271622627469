@import 'scss/base/colors.scss';
.container{
    background: #EDEDED;
    margin: 1px;
    text-align: center;
    font-size: 12px;
    padding: 2px;
    border-radius: 5px;
    margin: 3px;

    .labelIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            margin-left: 4px;
        }
    }

    .profileIcon {
        path {
            cursor: pointer;
        }
    }

    .editIcon {
        path {
            cursor: pointer;
        }
    }
}
.cancelled {
  background-color: $color-day-exception;
}

