.container {
  width: 100%;
  padding: 20px;
  .submissionComponents {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    .Button {
      float: right;
    }

  }
  .typeSelect {
    width: 600px;
  }

  .smallSelect {
    width: 30%;
  }

  .fullSelect {
    width: 100%;
  }
  .filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
      margin-top: 10px;
    }
  }

  .multiPreset {
    width: 100%;

    .multiPresetButtons {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      button {
        height: 24px;
        font-size: 16px;
      }
    }
  }

  .monthYear {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: auto;
    width: 700px;
  }

  .dateFilter {
    width: 100%;
    p {
      margin-bottom: 0;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.54);
    }
    span {
      margin: 0 10px;
      vertical-align: super;
    }
    svg {
      margin-top: 5px;
      margin-right: 10px;
    }
    .filter {
      margin: none;
    }
  }
}

.total {
  height: 50px;
  line-height: 50px;
  font-size: 18px;

  span {
    padding-right: 10px;
    font-size: 20px;
    font-weight: bold;
  }
}
