@import 'scss/base/colors.scss';

.padding {
  padding: 0.625rem;
  min-width: 350px;
}

.folderName {
  padding: 5px;
}

.fileSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;

  .fileName {
    font-size: 14px;
    line-height: 16px;
    color: $color-background-main;
    padding-left: 0.625rem;
  }
}

.choseFileHint {
  font-size: 14px;
  line-height: 16px;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 5px;
}

.container {
  margin-bottom: 5px;
  background-color: #eeeeee;
}

.error {
  color: $color-alert;
}

.success {
  color: $color-success;
}
