.container {
  .header {
    display: flex;
    flex-direction: row;

    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      font-family: 'Open Sans', sans-serif;
    }
  }
}
