.row {
  display: flex;
  flex-direction: row;
}

.rowCell {
  border: solid 0.5px #dfdfdf;
  padding: 15px 5px 0px 5px;

  &:first-child {
    flex: 2;
  }

  &:last-child {
    flex: 1;
  }
}

.header, .footer {
  font-weight: bolder;
  color: #4A5CC0;
  background-color: #4a5cc038;
  text-align: center;

  .rowCell {
    padding: 15px 5px;
    border: none;
  }
}

.padding {
  padding: 0.625rem;
}
