.main {
  display: flex;
  flex-direction: row;
  font-family: Roboto, serif;

  .avatar {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    background: #f8f8f8;
    border: 1px solid #e3e3e3;
    background-size: cover;
    background-position: center;
  }

  .data {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    justify-content: space-between;

    .nameAndType {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .name {
        font-weight: 500;
        font-size: 36px;
        color: #000000;
      }

      .type {
        font-size: 18px;
        color: #4a4a4a;
        padding-bottom: 5px;
        padding-left: 1rem;
      }
    }
  }
}
