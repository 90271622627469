@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  flex: 1;
  padding: 0 $page-left-right-padding $page-top-bottom-padding;
  display: flex;
  justify-content: center;
  // TODO: Fix styling instead of using this
  .innerContainerLimitWidthTmp {
    max-width: min-content;
  }

  .table {
    margin-bottom: 100px;
    max-width: min-content;
  }

}
