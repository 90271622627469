@import 'scss/base/sizes.scss';

.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 15px;
}

.form {
  margin-top: $margin-small;
}

.formListButtonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.folder {
  display: flex;
  min-width: 150px;
  margin-left: 15px;
}

.searchBar {
  width: 100%;
  align-self: flex-end;
}
