@import 'scss/base/colors.scss';

.dialogBody {
  .buttonSection {
    display: flex;
    flex-direction: row;

    .button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
  }
}

.red {
  color: red;
}

.space {
  justify-content: space-between;
}

.rightContent {
  justify-content: flex-end;
}

.textRight {
  text-align: right;
}

.withPadding {
  padding: 5px;
}
