@import 'scss/base/colors.scss';

.padding {
  padding: 0.625rem;
}

.details {
  padding: 0.625rem;
    .document {
      padding-left: 2.0rem;
    }
}

.options {
  margin-top: 30px;
  max-width: 450px;
}

.fileSelect {
  display: flex;
  flex-direction: row;
  align-items: center;

  .fileName {
    font-size: 14px;
    line-height: 16px;
    color: $color-background-main;
    padding-left: 0.625rem;
  }
}

.choseFileHint {
  font-size: 14px;
  line-height: 16px;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  color: $color-gray;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .marginLeft {
    margin-left: 1.25rem;
  }
}
