.dialog {
  .container {
    max-width: initial;

    .header {
      justify-content: space-between;
      display: flex;
  
      .title {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        padding: 15px 20px 0 20px;
      }
  
      .closeButton {
        padding: 12px 6px;
      }
    }
  }
}

