.data {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;

  .label {
    margin-left: 0px;
    font-size: 0.875rem;
    line-height: 21px;
    color: #4a4a4a;
    text-transform: capitalize;
  }

  .value {
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
  }
}