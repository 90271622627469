.collapseBtn {
  color: blue;
  cursor: pointer;
}

.description {
  display: block;
}

.descriptionText {
  all: unset;
}

.overflowHidden {
  overflow: hidden;
}

.overflowAuto {
  overflow: auto;
}
