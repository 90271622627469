@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;
  height: auto;
  width: 100%;
}

.formContainer {
  display: flex;

  .pdfColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;
  }

  .formColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;
    min-width: 200px;
    max-width: 400px;

    &:last-child {
      border: none;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    .availableOpening {
      font-weight: bold;
      font-size: $font-size-small;
      margin: 0;
      margin-bottom: $margin-5px;

      span {
        font-weight: normal;
      }

      &.openingTime {
        margin-bottom: $margin-larger;
      }
    }

    .steps {
      font-weight: bolder;
      font-size: $font-size-small;

      span {
        color: $color-blue;
        font-weight: bolder;
        font-size: $font-size-small;
      }

      &.step3 {
        margin-top: $margin-larger * 2;
      }

      &.step5 {
        margin-top: $margin-larger * 2;
      }
    }
  }

  .formFieldContainer {
    margin-bottom: $margin-medium;

    .textField {
      width: 100%;
    }
  }

  .healthCardContainer {
    margin-bottom: $margin-medium;
    display: flex;
    flex-direction: row;
    flex: 1;

    .hcn {
      max-width: 2.5rem;
      margin-left: 0.3125rem;
    }
  }
}
.stepOne{
  margin-top: 100px;
}
.stepTwo {
  margin-top: 100px;
}
.buttonSection {
  display: flex;
  justify-content: flex-end;
  height:50px;
  padding-top: 5px;
  padding-bottom: 5px;;
}

.verticalSeparator {
  width: 2px;
  height: 100%;
  background-color: $color-gray;

}