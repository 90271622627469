@import 'scss/base/sizes.scss';

.button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.tasks {
  padding-top: 0.25rem;

  .task {
    &:not(:first-child) {
      margin-top: $radius-small;
    }
  }
}

.paper {
  margin-top: 0.5rem;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.tasksAmount {
  width: 41px;
  height: 43px;
  margin-right: 10px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;

  color: #26315a;
}

.addBtn {
  background: #b00000;
  border-radius: 5px;
  margin-right: 10px;

  .addBtnIcon {
    height: 26px;
    width: 26px;
    margin-top: 5px;
    text-align: center;
  }
}
