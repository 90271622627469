.main {
  padding: 1.25rem;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 1.25rem;
  }

  .paddingRight {
    padding-right: 10px;
  }
}
