.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1.25rem;
  
    .gridContainer {
      width: 350px;
    }
  
    .buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 2.6875rem;
  
      .marginLeft {
        margin-left: 1.25rem;
      }
    }
  }