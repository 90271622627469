.container {
  display: flex;
  flex-direction: row;
  line-height: 25px;
  align-items: flex-start;
  padding-bottom: 3px;
  align-items: center;
  margin: 10px;
  margin-left: 0px;

  .title {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    margin-right: 5px;
    color: #4a4a4a;
  }

  .select {
    padding-top: 5px;
  }

  .selectComponent {
    min-width: 180px;
    max-width: 300px;
    border-bottom: solid 1px #e5e5e5;
  }

  .valueItem {
    font-size: 14px;
    border: solid 1px #e5e5e5;
    margin: 3px;
    border-radius: 4px;
    padding: 4px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }
}
