.main {
}

.left {
  padding-right: 1.25rem;
}

.right {
  padding-left: 1.25rem;
}
