@import 'scss/base/sizes.scss';

.button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.notes {
  padding-top: 0.25rem;

  .note {
    &:not(:first-child) {
      margin-top: $radius-small;
    }
  }
}

.paper {
  margin-top: 0.5rem;
}
