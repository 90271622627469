.textArea {
    border-bottom: none !important;
    padding-bottom: 0px !important;
}

.editable {
    &:hover {
        background-color: #e5e5e5;
    }
}

.paragraph {
    border-radius: 5px;
}