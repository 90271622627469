.container {
  padding: 20px;

  .text {
    font-size: 22px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .buttons {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
