.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.25rem;

  .gridContainer {
    width: 350px;
  }

  .topPadding {
    margin-top: 1.5rem;
  }

  .checkBox {
    display: flex;
    flex-direction: row;
  }

  .checkBoxText {
    text-align: center;
  }

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .input {
    margin-left: 3.5rem;
    display: flex;
    flex: 1;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.6875rem;

    .marginLeft {
      margin-left: 1.25rem;
    }
  }

  .paddingRight {
    padding: 0px 10px 10px 0px;
  }

  .paddingLeft {
    padding: 0px 0px 10px 10px;
  }
  
  .paddingRight div:nth-child(2), .paddingLeft div:nth-child(2){
    padding-bottom: 1rem;
  }

  .validationError {
    color: red;
    font-size: 13px;
    font-weight: 100;
  }
}

.response {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;

  .error {
    color: #b00000;
  }

  .success {
    color: #b1df95;
  }
}