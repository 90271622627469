.main {
  .healthCardContainer {
    display: flex;
    flex-direction: row;
    flex: 1;

    .hcn {
      max-width: 2.5rem;
      margin-left: 0.3125rem;
    }
  }
}
