@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.title {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: $color-background-main;
}

.creationInfo {
  padding-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $color-background-main;
  opacity: 0.5;
}

.nameLink {
  font-size: 0.875rem;
  text-decoration: none;
  color: $color-background-main;
}

.description {
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.125rem;
  white-space: pre-line;
  color: $color-gray;
}

.buttons {
  padding-top: 0.25rem;
  display: flex;
  justify-content: space-between;
}

.status, .link {
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: right;
  color: $color-link;
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
    color: $color-primary;
  }
}

.description {
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.125rem;

  color: $color-gray;
}

.infoRow {
  display: flex;
  flex-direction: row;

  .infoTitle {
    color: black;
    font-size: $font-size;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
  }

  .info {
    color: black;
    font-size: $font-size;
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none !important;
  }
  
}