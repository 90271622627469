.column {
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.resultColumn {
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.legEntries {
  display: flex;
  flex-direction: column;
}

.workflowEntriesWrapper {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
}

.title {
  font-size: 1.2rem;
}

.pageTitle {
  font-size: 2rem;
}
