@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';
.container {
    color:$color-primary;
    display: flex;
    flex-direction: row;

    .details{
        align-items: baseline;
        display: flex;
        flex-direction: row;

        .operation {
            font-weight: bold;  
            font-size: $font-size18px;
        }
    
        .description {
            margin-left: 5px;
            font-size: $font-size; 
        }
    }
  
    .resetBtn {
        border: none;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 20px;
        justify-content: right;
        background-color: transparent;
        background-size: contain;
        background-image: url('../../../../assets/svgs/closeButton.svg'); 
    }
}

.active {
    visibility: visible;
}

.disabled {
    visibility: hidden;
}