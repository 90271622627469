.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.25rem;

  .gridContainer {
    width: 350px;
  }

  .topPadding {
    margin-top: 1.5rem;
  }

  .checkBox {
    display: flex;
    flex-direction: row;
  }

  .checkBoxText {
    text-align: center;
  }

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  .staffInput {
    flex: 1;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.6875rem;

    .marginLeft {
      margin-left: 1.25rem;
    }
  }
}
