@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  flex: 1;
  padding: 0 $page-left-right-padding $page-top-bottom-padding;

  .needReferral {
    color: red;
    font-weight: 800;
  }

  .validationError {
    color: red;
    font-size: 13px;
    font-weight: 100;
  }

  .textError {
    color: red;
    text-align: end;
  }

  .tableContainer {
    margin-top: $page-fixed-header-content-top-padding;
  }
  .tableContainerFilterLocations {
    margin-top: $page-fixed-header-content-top-padding-extra-location-filter;
  }
}
