@import 'scss/base/colors.scss';

.container {
  background: $color-background-main;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    display: flex;
    flex-direction: column;
    padding: 1.6875rem 2.75rem;

    .fields {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px 0;

      .field {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 5px;
      }
    }
  }

  .logo {
    width: 16.625rem;
    height: 6.25rem;
  }

  .buttonWrapper {
    padding: 0 0.5rem;
  }

  .link {
    color: black;
    font-size: 0.8125rem;
    line-height: 1.625rem;
    padding-top: 5px;
    text-align: end;

    &:hover {
      color: $color-primary;
    }
  }
}
