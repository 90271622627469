@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.head {
  margin-top: 12px;
  padding-left: 20px;
}

.dateContent {
  display: flex;
  flex-direction: row;
  color: $color-gray;
  align-items: center;
  cursor: pointer;

  .date {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}
.signatureWarning {
  padding: 10px;
  font-size: 15px;
  word-wrap: normal;

  span {
    font-weight: bold;
  }
}

.headItem {
  font-style: normal;
  font-weight: bolder;
  font-size: 16px;
  line-height: 19px;
  color: $color-gray;
}

.contentItem {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: $color-gray;
}

.disabledText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: $color-text-disabled !important;
}

.goToItem {
  margin: auto;
  font-weight: bolder;
  color: $color-gray;
  float: right;
}

.mailIconButton{
  margin: 0;
  padding: 0;
}

.goToItem :hover {
  color: $color-blue;
  cursor: pointer;
}

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;

  .containerHeader {
    display: flex;
    justify-content: space-between;
    .appointmentOpeningInfo{
      p {
        margin: 0;
        margin-bottom: $margin-medium;
        font-weight: bolder;
        font-size: $font-size-small;
      }
    }
  }

  .buttonSection {
    display: flex;
    justify-content: flex-end;
  }
}

.canUpdate {
  cursor: pointer;
}

.buttons {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .button {
    &:not(:last-child) {
      margin-right: $margin-larger;
    }
  }
}
.necessityButton {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.selectdiv {
  margin: 15px;
  justify-content: center;
}

.buttondiv {
  margin: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}

.button {
  // background-color: $color-primary;
  // color: white;
  padding: '0 8px';
  font-size: '1rem';
  text-transform: 'none';
  white-space: 'nowrap';

  .disabled {
    pointer-events: none !important;
    background-color: rgba(99, 99, 99, 0.14) !important;
  }
}