.container {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;

  .padding {
    padding: 10px 0;
  }

  .body {
    display: flex;
    flex-direction: row;
    height: 16.875rem;
    width: 37.5rem;
    background: white;
    position: absolute;
    padding: 42px 20px 42px 42px;
    box-shadow: 0 0 10px #f2f2f2;
    right: 0;
    z-index: 1005;

    .avatar {
      width: 140px;
      height: 140px;
    }

    .info {
      padding-left: 30px;
      display: flex;
      flex-direction: column;
      flex: 1;

      .top {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: row;
        justify-content: space-between;

        .name {
          font-size: 35px;
          font-weight: bold;
        }
      }

      .userRole {
        color: rgb(200, 200, 200);
        font-weight: 500;
      }

      .bottom {
        display: flex;
        align-items: flex-end;
        flex: 1;

        .buttons {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .button {
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;

            &.logOut {
              color: #b00000;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.modalBackground {
  position: absolute;
  background-color: rgba(55, 111, 147, 0.2);
  height: 100%;
  width: 100%;
  z-index: 1000;
  bottom: 0;
}

.resetDialog {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-end;

    .button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.response {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;

  .error {
    color: #b00000;
  }
  
  .success {
    color: #b1df95;
  }
}
