.container {
  height: 100%;
  color: #26315a;
  font-size: 16px;
  line-height: 19px;
  padding-top: 13px;
  font-weight: normal;

  .active {
    color: #b00000;
  }

  .day {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }

  .month {
    text-overflow: clip;
    padding-left: 2px;
    padding-right: 2px;
    &.active {
      font-weight: bold;
    }
  }
}
