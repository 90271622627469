@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;

  .dialogBottom {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .buttomButtonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    padding-top: 20px;
    width: 100%;
    height: 60px;
  }

}
