.container {
  height: 100%;
  color: #26315a;
  font-size: 16px;
  line-height: 19px;
  padding-top: 13px;
  font-weight: normal;

  .day {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
  }
}
