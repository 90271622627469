@import 'scss/base/colors.scss';

.container {
  background: $color-background-main;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    display: flex;
    flex-direction: column;
    padding: 1.6875rem 2.75rem;

    .fields {
      display: flex;
      flex: 1;
      max-width: 16.625rem;
      flex-direction: column;
      padding: 20px 0;

      .field {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 5px;

        .info {
          width: 100%;
          text-align: center;
          color: $color-sub-info;
        }
      }
    }
  }

  .logo {
    width: 16.625rem;
    height: 6.25rem;
  }

  .buttonWrapper {
    padding: 0 0.5rem;
  }
}
