@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: $color-background-main;
  cursor: pointer;
  padding: 10px;

  &.bordered {
    border: solid 1px #4A5CC0;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    min-width: 14vw;
    
    * {
      color: #4A5CC0;
    }

    &.active {
      background-color: #4A5CC0;
      color: white !important;

      * {
        color: white;
      }
    }
  }

  &.negationLink {
    text-transform: uppercase;
    font-weight: bold;

    * {
      color: #B64040;
    }
  }

  &.affirmativeLink {
    color: #4A5CC0;
    text-transform: uppercase;
    font-weight: bold;

    * {
      color: #4A5CC0;
    }
  }

  &.disabled {
    color: $color-text-disabled;
    cursor: not-allowed;
    border: solid 1px $color-text-disabled;
    opacity: 0.6;
    pointer-events: none;
    
    * {
      color: $color-text-disabled;
    }

    &:hover {
      color: $color-text-disabled;
    }
  }

  .link {
    font-style: normal;
    font-weight: bold;
    font-size: $font-size-link;
    line-height: 1.6875rem;
    color: $color-background-main !important;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &.active {
      background-color: $color-background-secondary;
      color: white !important;
    }

    &:hover {
      text-decoration: none !important;
      color: $color-background-secondary !important;
    }
  }

  .progress {
    color: $color-background-secondary !important;
    margin-left: $margin-5px;
  }
}
