.main {
  font-family: Roboto, Serif;
  background: transparent;
  color: #26315a;
  padding: 0 8px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }

  .timePeriod {
    font-size: 12px;
  }

  .noLongerValid {
    color: red;
  }
}
