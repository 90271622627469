@import 'scss/base/colors.scss';

.padding {
  padding: 0.625rem;
  min-width: 350px;
}

.folderName {
  padding: 5px;
  margin-bottom: 20px;
}

.fileSelect {
  display: flex;
  flex-direction: row;
  align-items: center;

  .fileName {
    font-size: 14px;
    line-height: 16px;
    color: $color-background-main;
    padding-left: 0.625rem;
  }
}

.choseFileHint {
  font-size: 14px;
  line-height: 16px;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
  color: $color-gray;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
  }

  .marginLeft {
    margin-left: 1.25rem;
  }
}
