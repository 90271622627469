.dialogBody {
  padding: 0 25px 25px;

    .dialogBottom {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  
    .fields {
      display: flex;
      flex-direction: column;
    }

    .repeatContainer {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  
    .buttonSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .dialogButtonContainer {
      flex: 1;
      margin: 0 10px;
  
      &:first-child {
        margin: 0;
      }
  
      &:last-child {
        margin: 0;
      }
    }

    .buttomButtonSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        padding-top: 20px;
        width: 100%;
        height: 60px;
    }

    .datePickerWrapper{
        width: 60%;
        padding: auto;
    }

    .checkBoxWrapper{
        width: 40%;
        padding: auto;
    }

    .openingTimeWrapper{
        width: 95%;
        float: left;
    }

    .closingTimeWrapper{
        width: 95%;
        float: right;
    }

    .updateStaffWrapper {
        width: 50%;
        padding-left: 50px;
    }

    .editStaffSelect {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .dialogTopSection {
        height: 200px;
    }


    .repeatSchedule {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-size: 20px;
      padding-top: 15px;
      .checkBoxArea{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .checkBoxArea > *{
        padding-left: 15px;
        padding-right: 15px;
  
      } 
  
    }
  
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  
    .dialogButtonSelectedDisabled{
      background-color: #551d00;
      color:grey;
      border-radius: 5px;
      font-style: normal;
        font-weight: bold;
        font-size: 1.3125rem;;
        line-height: 1.6875rem;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: not-allowed;
   }

    .dialogButtonDisabled{
      background-color: white;
      color:grey;
      border-radius: 5px;
      font-style: normal;
        font-weight: bold;
        font-size: 1.3125rem;;
        line-height: 1.6875rem;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: not-allowed;
    }


  
    .topMargin {
      margin-top: 10px;
    }
  
    .btn {
      font-weight: bold;
      font-size: 21px;
      text-transform: uppercase;
      padding: 20px;
  
    }
  
    .rightBtn {
      float: right;
      color: #4052B6;
    }
    
    .leftBtn {
      float: left;
      color: #A44242;
    }
    .startEndDatesContainer {
      display: flex;
    }
  }
  
  .rightContent {
    justify-content: flex-end;
  }
  
  .withPadding {
    padding: 5px 10px 15px 10px;
    margin: 0px;
  }
  
  .dialogCondition {
    display: inline-flex;
    flex-grow: 1;
    .dialogLink{
      text-align: center;
      width: 100%;
    }
    
  }

  .editDeleteButton {
      display: flex;
      justify-content: center;
      align-items: center;
    background: #B64040;
    color: white;
    border-radius: 5px;
    padding: 10px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
  }
  

  .subTitle { 
    font-weight: bold;
    font-size: 20px;
    padding-top: 60px;
    padding-bottom: 20px;

  }

  .button {
    width: 100%;
  }
  
  .repeatSchedule {
    display: flex; 
    flex-direction: row;
    height: 100%; 
    flex: 1px; 
    align-items: center; 
    font-size: 20px; 
    padding: 10px;
    justify-content: space-between;
  
  }
  
  
  