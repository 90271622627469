@import 'scss/base/colors.scss';


.dialogBody {
  .buttonSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  .validationError {
    color: red;
    font-size: 13px;
    font-weight: 100;
  }

  .textError {
    color: red;
    text-align: end;
  }
}

.orderNumber{
  position: absolute;
  top: 5px;
  left: 290px;
}

.withPadding {
  padding: 0 10px 10px 10px;
}

.scheduleType {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 10px 10px 10px;
  height: 100%;
}

.saveOrClose {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 1.6875rem;
  text-transform: uppercase;
  color: black !important;
  text-decoration: none;
}

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #B64040;
  color: white;
  border-radius: 5px;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
}
