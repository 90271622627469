@import 'scss/base/colors.scss';

.dialogBody {
  .buttonSection {
    display: flex;
    flex-direction: row;

    .button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .fields {
    display: flex;
    flex-direction: column;
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .checkbox, .referrerName {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
  }
}

.error {
  color: red;
  font-weight: 100;
}

.validationError {
  color: red;
  font-weight: 100;
}

.space {
  justify-content: space-between;
}

.rightContent {
  justify-content: flex-end;
}

.textRight {
  text-align: right;
}

.withPadding {
  padding: 5px;
}

.topMargin {
  margin-top: 10px;
}

.withMorePadding {
  padding: 15px;
}

.otherMails {
  padding: 5px;
  display: flex;
  align-items: center;
  
  .addCircleIcon {
    &:hover {
      color: $color-link;
      cursor: pointer;
    }
  }

  .RemoveCircleIcon {
    &:hover {
      color: $color-link;
      cursor: pointer;
    }
  }
}

.allMails {
  width: 85%;
  border: 1px solid gray;
  border-radius: 5px;
  margin: auto;
}

.smallerText {
  label {
    font-size: small
  }
}
