@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';


.bottom {
  justify-content: flex-end;
}

.dialogBody{
  margin:20px
}

.head{
  position: sticky;
    top: 0px;
    z-index: 1;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttonRow{
  justify-content: end;

  .button{
    margin:10px
  }
}

.appointmentSelectionTable{    width: 80vw;
  max-height: 400px;
  overflow: auto;
}

