@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  padding: 0 $padding-large;
  padding-bottom: $padding-larger;
}


.formContainer {
  display: flex;

  .formColumn {
    flex: 1;
    padding: 0 $padding-large;
    border-right: solid 1px $color-light-grey-border;

    &:last-child {
      border: none;
      padding-right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .formFieldContainer {
    margin-bottom: $margin-medium;
  }
}

.checkbox {
  display: flex;
  align-items: center;
}

.buttonSection {
  display: flex;
  justify-content: flex-end;
}


.buttonPlus {
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.buttonPlus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #4052b6;
  height: 50%;
  width: 3px;
  border-radius: 4px;
}

.buttonPlus:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  height: 3px;
  width: 50%;
  background: #4052b6;
  top: 50%;
  left: 50%;
  border-radius: 4px;
}

.textDisabled {
  color: $color-text-disabled;
}