@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  flex: 1;
  padding: 0 $page-left-right-padding $page-top-bottom-padding;

  .tableContainer {
    margin-top: $page-fixed-header-content-top-padding;
  }
}
