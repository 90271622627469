@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  flex: 1;
  padding: 0 $page-left-right-padding $page-top-bottom-padding;

  .tableContainer {
    margin-top: $margin-small;
  }
}

.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 15px;
}

.refresh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  min-width: 110px;
}

.formListButtonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding: 0 $page-left-right-padding 0;
}

.checkbox {
  display: flex;
  align-items: center;
}


