.container {
  display: flex;
  flex-direction: row;
  padding: 0.625rem 0;
  align-items: center;
  border-bottom: 0.0625rem solid #cfcfcf;

  .dot {
    background: #c4c4c4;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
  }

  .data {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    flex: 1;
    overflow: auto;

    .label {
      font-size: 0.875rem;
      line-height: 1rem;
      color: #4a4a4a;
      text-transform: capitalize;
    }

    .value {
      margin-top: 0.125rem;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
    }
  }
}
