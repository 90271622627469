.container {
  width: 100%;
  height: 100%;
  .confirmed {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 30px;
  }
  .backgroundImage {
    margin-top: 30px;
    margin-left: 30px;
  }
  .extraInfo {
    margin-top: 100px;
    font-size: 18px;
  }
}
