@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.container {
  height: 80vh;
  display: flex;
  flex-direction: column;

  .instructions {

    padding: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .iframe {
  
    flex:1;
    margin-bottom: 70px;
  }
}

