@import 'scss/base/colors.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.25rem;

  .title {
    display: flex;
    font-weight: bold;
    font-size: 2em;
    line-height: 42px;
    padding-right: 5px;
    align-items: flex-start;
    width: 260px;
  }

  .toolbar {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .gridContainer {
    width: 350px;
  }

  .topPadding {
    margin-top: 1.5rem;
  }

  .checkBox {
    display: flex;
    flex-direction: row;
  }

  .checkBoxText {
    text-align: center;
  }

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .input {
    margin-left: 3.5rem;
    display: flex;
    flex: 1;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 2.6875rem;

    .marginLeft {
      margin-left: 1.25rem;
    }
  }

  .paddingRight {
    padding: 0px 10px 10px 0px;
  }

  .paddingLeft {
    padding: 0px 0px 10px 10px;
  }
  
  .paddingRight div:nth-child(2), .paddingLeft div:nth-child(2){
    padding-bottom: 1rem;
  }

  .validationError {
    color: $color-alert;
    font-size: 13px;
    font-weight: 100;
  }
}

.textError {
  color: $color-alert;
  text-align: center;
}

.circularProgress {
  color: $color-blue;
}

.selectors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.checkbox {
  margin-right: 15px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .buttons {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-left: 90px;

      .button {
        cursor: pointer;
        font-weight: bold;
        font-size: 10px;

        &.active {
          color: #b00000;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    .marginLeft {
      margin-left: 40px;
    }

    .marginTop {
      margin-top: 2px;
    }
  }
}
