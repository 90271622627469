.viewer {
    width: 100%;
    height: 100vh;
  }

  .generateButton {
    float: right;
  }

  .backToPatient {
    float: left
  }

  .divider {
    width:5px;
    height:auto;
    display:inline-block;
  }

  .generatingReportTakeover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9;

    p {
        position: absolute;
        font-weight: bold;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 40px;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
  }