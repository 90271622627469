.patientInfo {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  width: 100%;
  box-shadow: 4px 0 10px #e8e8e8;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &,
  * {
    box-sizing: border-box;
  }

  .marginRight {
    margin-right: 1rem;
  }

  .noInfo {
    text-align: center;
    padding: 0.3125rem;
    color: #999999;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 0;

    .patientName {
      width: 100%;
      font-weight: normal;
      font-size: 2.25rem;
      line-height: 2.625rem;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date {
      margin-top: 0.3125rem;
      font-size: 1.125rem;
      line-height: 1.3125rem;
      text-align: center;
      display: flex;
      flex-direction: row;

      .gender {
        margin-left: 5px;
        text-transform: capitalize;
      }
    }

    .buttonMargin {
      margin-top: 2.125rem;
      display: flex;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  .title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    font-variant: small-caps;
    padding-top: 2.5rem;
    text-align: center;
    color: #4a4a4a;
  }
}

.newAppointment {
  margin-top: 10px;
  text-decoration: none;
}

.patientInfo::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
