.margin {
  padding: 20px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.6875rem;

  .marginLeft {
    margin-left: 1.25rem;
  }
}
