@import 'scss/base/colors.scss';

.dailyHoursAvailabilityContainer {
  flex: 1;

  .typeHeader {
    margin: 5px;
    text-align: center;
  }
}

.dailyHoursAvailabilityContent {
  padding: 5px;
  margin-left: -1px;
  border-top: solid 1px $color-light-grey-border;
  border-left: solid 1px $color-light-grey-border;
  border-bottom: solid 1px $color-light-grey-border;
  display: flex;
  flex-wrap: wrap;
}

.staffHeaders {
  width: 100%;
}
.providerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.providerWrapper:not(:first-child) {
  border-top: 1px solid $color-light-grey-border;
}

.dailyHours {
  position: relative;
  border: solid 1px $color-light-grey-border;
  border-radius: 4px;
  font-size: 10px;
  padding-left: 5px;
  min-width: calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  flex: 1;
  margin: 5px;

  span {
    font-weight: bolder;
  }
}

.buttonPlus {
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.buttonPlus:after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  height: 3px;
  width: 50%;
  background: #4052b6;
  top: 50%;
  left: 50%;
  border-radius: 4px;
}

.buttonPlus:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #4052b6;
  height: 50%;
  width: 3px;
  border-radius: 4px;
}

.buttonShow {
  color: #4052b6;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  height: 25px;
  line-height: 25px;
}

.noOpenings {
  color: #4052b6;
  text-align: center;
  margin-left: 10px;
}

.disabled {
  pointer-events: none;
  background-color: rgba(99, 99, 99, 0.14);
}
