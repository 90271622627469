@import 'scss/base/colors.scss';

.dialogBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2rem;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flexEnd {
    justify-content: flex-end;
  }

  .buttonSection {
    display: flex;
    flex-direction: row;

    .button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.pdf {
  width: 50rem;
  height: 75rem;
}

.space {
  justify-content: space-between;
}

.padding {
  padding: 0.625rem;
}

.fullFlex {
  flex: 1;
}

.disabled {
  color: $color-text-disabled;
}

.newRecipients {
  display: flex;
  align-items: center;
  flex-direction: row;

  .addCircleIcon {
    &:hover {
      color: $color-link;
      cursor: pointer;
    }
  }

  .RemoveCircleIcon {
    &:hover {
      color: $color-link;
      cursor: pointer;
    }
  }
}

.allRecipients {
  width: 85%;
  border: 1px solid gray;
  border-radius: 5px;
  margin: auto;
}
